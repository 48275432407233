<script setup lang="ts">
const { posts } = defineProps<{
  posts: { id: number }[]
}>()

const { data: mobileAds } = await useFetch('/api/legacy/lazy/landing/ads/mobile')

const bannerFrequency = 3

const { isMobile } = useDevice()

const interleavedItems = computed(() => {
  const output = []

  for (let i = 0; i < posts.length; i++) {
    output.push({ ...posts[i], type: 'article' })
    if ((i + 1) % bannerFrequency === 0) {
      if (mobileAds.value.length > 0 && isMobile) {
        const staticBanner = mobileAds.value[Math.floor(i / bannerFrequency) % mobileAds.value.length]

        output.push({ ...staticBanner, type: 'banner' })
      }
    }
  }

  return output
})
</script>

<template>
  <UContainer class="pb-8">
    <div class="flex flex-col md:flex-row divide-x divide-gray-100">
      <div class="flex-1 flex flex-col gap-y-6 py-4 md:pr-4">
        <div class="grid grid-cols-1 xl:grid-cols-3 gap-4">
          <div
            v-for="item in interleavedItems"
            :key="item.id"
          >
            <CleanCard
              v-if="item.type === 'article'"
              :id="item.id"
            />
            <div
              v-else
            >
              <NuxtLink
                :title="item?.link?.title"
                :to="item?.link?.url"
                :target="item?.link?.target"
              >
                <AppImage
                  v-if="item?.image"
                  :id="item.image"
                  :width="300"
                  :height="250"
                  class="rounded shadow w-full h-full"
                />
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>

      <LandingPostsAside v-if="$device.isDesktop" />
    </div>

    <UButton
      icon="i-bitcoin-icons-arrow-right-filled"
      trailing
      variant="link"
      size="xl"
      label="Zobacz więcej"
      to="/wiadomosci"
    />
  </UContainer>
</template>
