<script setup lang="ts">
const { data } = useLazyFetch('/api/posts', {
  key: 'landing/audios',
  query: {
    per_page: 4,
    categories: '5781',
  },
})
</script>

<template>
  <ULandingSection
    title="Bitcoin Radio"
    description="Podcast o bitcoinie, kryptowalutach i technologii blockchain."
  >
    <div class="grid gap-5 max-w-lg mx-auto lg:grid-cols-4 lg:max-w-none">
      <CleanCard
        v-for="post in data?.posts"
        :id="post.id"
        :key="post.id"
      />
    </div>
  </ULandingSection>
</template>
