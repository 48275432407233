<script setup lang="ts">
const { data } = useLazyFetch('/api/posts', {
  key: 'landing/videos',
  query: {
    per_page: 4,
    tags: '5506',
  },
})
</script>

<template>
  <ULandingSection
    title="Newsy Wideo"
    description="Newsy wideo z rynku bitcoina, kryptowalut i technologii blockchain we współpracy z kanałem Krypto Raport."
    class="dark bg-gray-900"
  >
    <div class="grid gap-5 max-w-lg mx-auto lg:grid-cols-4 lg:max-w-none">
      <CleanCard
        v-for="post in data?.posts"
        :id="post.id"
        :key="post.id"
      />
    </div>
  </ULandingSection>
</template>
