<script setup lang="ts">
const { data } = await useFetch('/api/legacy/front', {
  key: 'landing',
})

const { data: posts } = await useFetch('/api/posts', {
  key: 'landing/posts',
  query: {
    per_page: 9,
  },
})

definePageSeo(data.value.seo)
definePageScripts(data.value.enqueuedScripts)
definePageMeta({
  colorMode: 'light',
})
</script>

<template>
  <div>
    <LandingPosts
      :posts="posts.posts"
    />

    <AppNewsletter large />

    <LandingAudios />
    <LandingVideos />
    <LandingFAQ :data="data?.details" />
    <LandingProducts :products="data.suggestions" />
    <LandingPartners :partners="data.partners" />
  </div>
</template>
