<script setup lang="ts">
const { data } = await useFetch('/api/legacy/lazy/landing/ads')
</script>

<template>
  <aside
    id="ads"
    class="flex flex-col gap-y-4 p-4"
  >
    <div
      v-for="ad in data"
      :key="ad?.link?.url"
      class="sticky top-16"
    >
      <NuxtLink
        :to="ad?.link?.url"
        :title="ad?.link?.title"
        :target="ad?.link?.target"
      >
        <AppImage
          v-if="ad?.image"
          :id="ad.image"
          :width="300"
          :height="250"
          class="rounded shadow w-full h-full md:h-[250px] md:w-[300px]"
        />
      </NuxtLink>
    </div>
  </aside>
</template>
